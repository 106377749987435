import { Form } from 'dataformjs';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import GridSplit from '../../../components/base/GridSplit';
import organizationData from '../../../objects/organizations/datas.json';
import createMutation from '../../../objects/organizations/mutations/create.graphql';
import pageProps, { ResultProps } from '../../../utils/pageProps';
import requiredAuth from '../../../utils/requireAuth';

const OrganizationsCreatePage: FC<ResultProps> = () => {
  const formName = 'organization';
  const [createMutationFunc] = useMutation(createMutation);
  const { t } = useTranslation();

  const handleOnSubmit = async (values: any) => {
    const result = await createMutationFunc({
      variables: values,
    });

    if (!result || result.errors) {
      console.error(result.errors);
    } else {
      const organizationId = result.data.organizationsCreate.id;
      window.history.replaceState({}, '', `/admin/organizations/`);
      navigate(`/admin/organizations/update/${organizationId}/`);
    }
  };

  return (
    <GridSplit
      header={{
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: `Création d'une organisation`,
      }}
    >
      <Form
        className="mx-6 mb-6"
        datas={organizationData}
        footerProps={{
          className: 'mt-12 flex justify-end',
        }}
        name={formName}
        onSubmit={handleOnSubmit}
        submitProps={{
          label: 'Créer cette organisation',
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(pageProps(OrganizationsCreatePage), {
  admin: true,
  layout: true,
});
